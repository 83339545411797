<template>
  <div class="home-banner">
    <div class="banner-slide">
      <div class="banner-image">
        <img src="assets/img/MAS-building-5.jpg" loading="lazy" alt="banner" />
      </div>
      <div class="banner-content">
        <div class="mas-container">
          <h1>
            {{ bannerMsg }}
          </h1>
          <div class="buttons-row">
            <button
              class="xb-button button is-primary"
              rel="noreferrer"
              @click="login('corppass')"
            >
              CORPPASS LOGIN
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useToppan } from "@/composables/useToppan";

const { login } = useToppan();
const bannerMsg = ref("Login with your CorpPass to the MAS Bill Portal");
</script>

<style lang="scss">
.home-banner {
  .banner {
    &-image {
      display: contents;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0.76) 23.87%,
          rgba(0, 0, 0, 0) 100%
        );
      }
    }
  }
}
</style>
