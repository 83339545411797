<template>
  <div class="sgds-masthead">
    <div class="sgds-masthead__container">
      <div
        class="sgds-masthead__wrapper"
        :class="{ 'sgds-masthead__wrapper--centered': centered }"
      >
        <div class="sgds-masthead__trigger">
          <a class="sgds-masthead__title" href="https://www.gov.sg/">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              width="1024"
              height="1024"
              viewBox="0 0 1024 1024"
              class="sgds-masthead__sg-crest-logo"
            >
              <title></title>
              <g id="icomoon-ignore"></g>
              <path
                d="M110.648 352.248c0 0-37.49 52.949 11.624 121.438 0 0 7.754-34.877 85.256-34.877h93.050c87.867 0 153.747-83.952 112.383-170.554 0 0 62.002 6.485 82.729-32.306 20.639-38.755-1.304-54.255-32.306-54.255h-156.359c0 27.8-52.949 31.677-52.949 0h-87.867c0 0-65.921 0-67.185 55.559 0 0 14.868-9.058 29.697-10.32v14.824c0 0-18.073 3.242-26.497 7.796-8.382 4.504-20.639 16.762-9.016 48.439 11.624 31.635 16.135 42.628 16.135 42.628s18.742-16.177 49.115-16.177h35.555c63.268 0 51.639 61.374-11.665 61.374s-71.694-33.573-71.694-33.573zM440.757 288.284c0 0 21.315 1.304 36.817-12.258 0 0 140.183 109.182-67.817 328.808-208.043 219.625-47.177 361.789-47.177 361.789s-37.446 36.139-16.135 106.57c0 0-86.518-48.863-152.694-131.8-95.659-119.88-154.084-303.323 108.55-449.615 0 0 173.334-87.194 138.456-203.494zM226.485 161.032c0 0 28.010-49.959 93.006-49.959 51.263 0 62.929-26.287 62.929-26.287s22.369-42.628 135.213-42.628c103.41 0 173.123 34.457 229.525 80.537 0 0-152.019-94.314-301.892 38.329h-218.784zM858.712 477.136c-4.296-155.894-124.892-322.995-384.156-313.517 253.24-214.486 692.534 264.402 330.745 515.956 0 0 60.531-86.308 53.411-202.442zM513.32 180.84c316.129-9.476 428.975 369.498 224.807 540.050l-205.009 99.072c0 0-26.706-86.142 72.366-186.939 99.072-100.756 194.648-289.38-79.231-413.432 0 0 5.18-25.82-12.935-38.755zM496.947 266.112c0 0 15.5-14.656 20.681-26.706 235.126 98.188 198.101 264.444 57.707 403.997-86.142 88.709-59.435 184.327-59.435 184.327s-105.053 65.418-141.237 122.281c0 0-152.104-129.526 58.55-344.518 206.736-211.033 63.731-339.379 63.731-339.379z"
              ></path>
            </svg>
            <span>A Singapore Goverment Agency Website</span>
          </a>

          <div
            v-if="showTriggerDropdown && !isMobileView"
            class="sgds-masthead__dropdown-trigger"
            :class="{ 'sgds-masthead__dropdown-trigger--active': active }"
            @click="toggleActive"
          >
            <span class="sgds-masthead__dropdown-trigger-text">
              How to identify
            </span>
            <o-icon
              class="sgds-masthead__dropdown-trigger-icon"
              pack="fa"
              icon="angle-down"
            />
          </div>
        </div>
      </div>
      <div
        v-if="showTriggerDropdown && active && !isMobileView"
        class="sgds-masthead__wrapper"
        :class="{ 'sgds-masthead__wrapper--centered': centered }"
      >
        <div class="sgds-masthead__dropitem-container">
          <div class="sgds-masthead__dropitem">
            <o-icon
              class="sgds-masthead__dropitem-icon"
              pack="fa"
              icon="university"
            />

            <div>
              <h3 class="sgds-masthead__dropitem-title">
                Official webiste links end with .gov.sg
              </h3>

              <p class="sgds-masthead__dropitem-text">
                Government agencies communicate via
                <strong>.gov.sg</strong> websites (e.g. go.gov.sg/open).
                <a href="/"
                  >Trusted websites<o-icon pack="fa" icon="external-link"
                /></a>
              </p>
            </div>
          </div>

          <div class="sgds-masthead__dropitem">
            <o-icon
              class="sgds-masthead__dropitem-icon"
              pack="fa"
              icon="lock"
            />

            <div>
              <h3 class="sgds-masthead__dropitem-title">
                Secure websites use HTTPS
              </h3>

              <p class="sgds-masthead__dropitem-text">
                Look for a
                <strong>lock (<o-icon pack="fa" icon="lock" />)</strong> or
                https:// as an added precaution. Share sensitive information
                only on official, secure websites.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps } from "vue";
import { useMq } from "vue3-mq";

const mq = useMq();

defineProps({
  centered: {
    type: Boolean,
    default: false,
  },
  showTriggerDropdown: {
    type: Boolean,
    default: false,
  },
});

const active = ref(false);
const isMobileView = computed(
  () => mq.current === "smallMobile" || mq.current === "mobile",
);

function toggleActive() {
  active.value = !active.value;
}
</script>

<style lang="scss">
.sgds-masthead__dropdown-trigger-text {
  color: #2f5fd0;
}

.sgds-masthead {
  &__container {
    background-color: #f0f0f0;
  }

  &__wrapper {
    padding: 0 20px;

    &--centered {
      max-width: 1264px;
      padding: 0 10px;
      margin: 0 auto;
    }
  }

  &__trigger {
    display: flex;
    gap: 5px;
    height: 30px;
  }

  &__title {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    color: #4a4a4a;
    text-decoration: none;

    &:hover {
      color: #4a4a4a;
      text-decoration: none;
    }
  }

  &__sg-crest-logo {
    width: 15px;
    height: 25px;
    fill: #db0000;
    margin-right: 5px;
  }

  &__dropdown {
    &-trigger {
      color: #8d7249;
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 400;
      cursor: pointer;

      &-text {
        text-decoration: underline;
      }
      &-icon {
        width: 16px;
        transition: all 0.2s ease;
      }

      &--active {
        .sgds-masthead__dropdown-trigger {
          &-icon {
            transform: rotate(-180deg);
          }
        }
      }
    }
  }

  &__dropitem {
    display: flex;
    gap: 10px;
    max-width: 420px;
    color: #4a4a4a;

    &-container {
      display: flex;
      gap: 24px;
      padding: 24px 0;
    }

    &-title {
      font-size: 20px;
      line-height: 1.5rem;
      font-weight: 600;
      margin: 0;
    }

    &-text {
      font-weight: 400;
      margin: 0;
    }

    &-icon {
      font-size: 20px;
      line-height: 1.5rem;
    }

    a {
      color: #8d7249;
      text-decoration: none;

      &:hover {
        color: #8d7249;
        text-decoration: none;
      }
    }
  }
}
</style>
